import { QueryClient, useQuery } from '@tanstack/vue-query'
import { ref } from 'vue'
import QueryKeys from '@/vue-query/QueryKeys'
import { AuditInstanceDto } from '@/api/contracts/models'
import { useAuditInstanceApi } from '@/composables/api/useAuditInstanceApi'

export function useAuditInstancesQuery({ enabled } = { enabled: ref(true) }) {
   const client = useAuditInstanceApi()
   return useQuery([QueryKeys.AuditInstances], () => client.list(), {
      enabled
   })
}

export function useAuditInstancesQueryPrefetch(
   queryClient: QueryClient
): Promise<void> {
   const client = useAuditInstanceApi()
   return queryClient.prefetchQuery<AuditInstanceDto[]>(
      [QueryKeys.AuditInstances],
      () => client.list()
   )
}
